import NextLink from 'next/link';
import { IP40ProductListPage } from '~/lib/data-contract/umbraco-api-model';
import { useNavigation, usePage } from '~/services';
import { Link } from '~/shared/components/Link';
import { NavigationItem } from './NavigationItem';
import { List, MenuTitle } from './styled';

const Navigation = () => {
    const { data: page } = usePage<IP40ProductListPage>();
    const { data: navigation } = useNavigation(2);
    const secondLevelCategory = page?.breadcrumb?.items?.[2];

    return (
        <>
            {secondLevelCategory ? (
                <MenuTitle variant="display5" as="h3">
                    <NextLink
                        prefetch={false}
                        href={secondLevelCategory?.url || '/'}
                        passHref
                        legacyBehavior
                    >
                        <Link animation="reverse" title={secondLevelCategory.title}>
                            {secondLevelCategory.text}
                        </Link>
                    </NextLink>
                </MenuTitle>
            ) : null}

            {navigation?.children ? (
                <List>
                    {navigation.children.map((item, index) => (
                        <NavigationItem key={`SideNavigationItem-${item.id}-${index}`} {...item} />
                    ))}
                </List>
            ) : null}
        </>
    );
};

export default Navigation;
