import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

interface AddBasketItemArguments extends ShopTypes.BasketItemOptions {
    itemNo: string | undefined;
    reference: string | undefined;
}

export default function addBasketItem({ itemNo, reference, ...rest }: AddBasketItemArguments) {
    if (!reference) {
        return Promise.reject(new Error(Shop.errors.referenceNotFound.message));
    }

    if (!itemNo) {
        return Promise.reject(new Error(Shop.errors.invalidProductItemNumber.message));
    }

    return shop
        .post(Shop.utils.getUrl(`/baskets/${reference}/add/${itemNo}`), {
            json: rest as ShopTypes.BasketItemOptions,
            hooks: {
                beforeError: [async (error) => await error.response.json()],
            },
        })
        .json<ShopTypes.BasketResponse>();
}
