import { HTTPError } from 'ky';
import { useQuery } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { useBasketReference } from '~/shared/utils/storage/hooks';

export default function useBasket<SelectType = Basket>(select?: (data: Basket) => SelectType) {
    const [reference] = useBasketReference();

    return useQuery<Basket, HTTPError | Error, SelectType>(
        ['basket', { reference }],
        () => Shop.operations.getBasket(reference),
        {
            retry: (failureCount, error) => {
                // If basket not found, we use the global onError instead since this will be triggered multiple times.
                if (error instanceof HTTPError && error.response?.status === 404) {
                    return false;
                }

                // Any other failed request will be retried. We assume that any other error then 404 is backend errors
                return failureCount < 2;
            },
            enabled: !!reference,
            select,
            staleTime: 5 * 60 * 100, // 5 minutes
            cacheTime: 30 * 1000, // 30 seconds
            refetchOnWindowFocus: true,
            meta: {
                type: 'getBasket',
            },
        },
    );
}

type Basket = ShopTypes.BasketResponse;
