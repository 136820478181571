import { ShopTypes } from '~/lib/data-contract';

export function getTotalBasketDiscount(basket: ShopTypes.BasketContextResponse[] | undefined) {
    let totalDiscount = 0;

    basket?.forEach(
        (context) =>
            context.freights?.forEach((freight) => {
                freight.brands?.forEach(
                    (brand) =>
                        brand.items?.forEach((item) => {
                            totalDiscount += item?.totalDiscount?.priceInclVat || 0;
                        }),
                );
            }),
    );

    return totalDiscount;
}
