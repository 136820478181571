import { Loop54Product, useTranslations, useUserProfile } from '~/services';
import { ProductCard } from '../ProductCard';
import { Grid } from './styled';
import { useMemo } from 'react';
import { useSession } from 'next-auth/react';

interface Props {
    items: Loop54Product[] | undefined;
    itemListName?: string;
}

export default function ProductList({ items, itemListName }: Props) {
    const { translate } = useTranslations();

    const { data: sessionData } = useSession();
    const { data: profile } = useUserProfile();
    const token = sessionData?.accessToken ?? null;
    const customerId = profile?.customer?.id ?? '';

    const productsWithPrices = useMemo(
        () =>
            items?.map((item) => {
                return {
                    key: item.Id,
                    product: item,
                };
            }),
        [items, customerId],
    );

    return (
        <Grid>
            {productsWithPrices?.map((product) => (
                <ProductCard
                    key={product.product.Id}
                    product={product.product}
                    translate={translate}
                    itemListName={itemListName}
                    customerId={customerId}
                    token={token}
                />
            ))}
        </Grid>
    );
}

function ProductListSkeleton() {
    return (
        <Grid>
            {Array.from({ length: 10 }).map((_, index) => (
                <ProductCard.Skeleton key={index} />
            ))}
        </Grid>
    );
}

ProductList.Skeleton = ProductListSkeleton;
